var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('g-form',{on:{"submit":_vm.save}},[_c('b-row',{staticClass:"center-element"},[(_vm.helper.showAlert)?_c('label',{staticClass:"main-font-size main-space mb-2 p-75",staticStyle:{"background-color":"#9b6f16","color":"#FFF","border-radius":"5px"}},[_c('feather-icon',{staticClass:"mr-50 b-avatar-sm",attrs:{"icon":"AlertTriangleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('advancePaymentStartsFromNextMonthAsCurrentMonthSalaryIsCalculated'))+" ")],1):_vm._e()]),_c('b-row',[(_vm.selectedItem.id > 0)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"id":"code","type":"number","label-text":"transactionNumber","name":"code","readonly":"","value":_vm.selectedItem.code},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "code", $event)}}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.getDate(_vm.selectedItem.transactionDate),"label-text":"transactionDate","disabled":"","name":"transactionDate"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.employeeId,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"employee","field":"select","name":"employee","rules":"required","options":_vm.employees,"label":_vm.isRight ? 'arabicName' : 'englishName',"disabled":_vm.selectedItem.id > 0},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "employeeId", $event)},"change":function (v) {
              _vm.selectedItem.departmentId = v.departmentId;
              _vm.isCurrentEmployeeAlreadyHavePayment(v.id);
            }}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.departmentId,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"department","field":"select","name":"department","options":_vm.departments,"disabled":"","label":_vm.isRight ? 'arabicName' : 'englishName'},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "departmentId", $event)}}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.total,"label-text":"advancePaymentValue","name":"scalesCode","type":"number","rules":"required"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "total", $event)},"change":function () {
              _vm.calculate(_vm.selectedItem.installmentsNumber)
            }}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"id":"code","name":"code","value":_vm.selectedItem.installmentsNumber,"label-text":"installmentsNumber","rules":"required","type":"number"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "installmentsNumber", $event)},"change":function (installmentsNumber) {
              _vm.calculate(installmentsNumber)
            }}})],1)],1),_c('b-col',{staticClass:"installments-methedolgy",attrs:{"md":"4"}},[_c('label',{staticClass:"mb-50 main-font-size"},[_vm._v(" "+_vm._s(_vm.$t('paymentAdvancePeriod'))+" ")]),_c('b-col',{staticClass:"center-element flex-column",attrs:{"md":"12"}},[_c('div',{staticClass:"center-element"},[_c('b-form-group',{staticStyle:{"width":"100px"}},[_c('g-field',{attrs:{"value":_vm.selectedItem.numberOfMonths,"type":"number","name":"scalesCode","readonly":""},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "numberOfMonths", $event)}}})],1),_c('label',{staticClass:"mr-2 ml-50 main-space",staticStyle:{"margin-top":"-20px"}},[_vm._v(" "+_vm._s(_vm.$t('month'))+" ")]),_c('b-form-group',{staticStyle:{"width":"100px"}},[_c('g-field',{attrs:{"value":_vm.selectedItem.numberOfYears,"name":"scalesCode","type":"number","readonly":""},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "numberOfYears", $event)}}})],1),_c('label',{staticClass:"ml-50 main-space",staticStyle:{"margin-top":"-20px"}},[_vm._v(" "+_vm._s(_vm.$t('year'))+" ")])],1),_c('label',{staticClass:"mb-2 main-space",staticStyle:{"margin-top":"-20px","color":"#283494"}},[_vm._v(" "+_vm._s(("** " + (_vm.$t('peroidEndDate')) + " : " + (_vm.helper.advancePaymentPeroidEndDate)))+" ")])])],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.value,"name":"scalesCode","label-text":"monthlyPaidValue","type":"number"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "value", $event)},"change":function (monthlyPaidValue) {
              _vm.calculateReverse(monthlyPaidValue);
            }}})],1),(!_vm.helper.isValidRange || !_vm.helper.isValidMonthlyValue)?_c('label',{staticClass:"mb-1 main-space",staticStyle:{"margin-top":"-20px","color":"red"}},[_vm._v(" "+_vm._s(_vm.getHintMessage())+" ")]):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{staticClass:"main-font-size"},[_vm._v(" "+_vm._s(_vm.$t("notes"))+" ")]),_c('b-form-textarea',{attrs:{"id":"textarea","label":"Notes","rows":"3","max-rows":"6"},model:{value:(_vm.selectedItem.notes),callback:function ($$v) {_vm.$set(_vm.selectedItem, "notes", $$v)},expression:"selectedItem.notes"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"relief-primary","data-action-type":"save"},on:{"click":function($event){return _vm.save('saveOnly')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(this.$t('save'))+" ")],1),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-primary","data-action-type":"save"},on:{"click":function($event){return _vm.save('saveAndPrint')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PrinterIcon"}}),_vm._v(" "+_vm._s(this.$t('saveAndPrint'))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }